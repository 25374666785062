.project-detail {
    .row-custom {
        .col-xs-12 {
            &:first-of-type() {
                padding: 0;
            }
        }
    }
    .inner-gallery {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    &.light-skin {
        .m-video-large {
            background-color: $light_default_bg;
            .video {
                .img {
                    opacity: .3;
                }
                iframe {
                    background-color: $light_default_bg;
                }
            }
        }
    }
    .detail-video-full {
        .video {
            height: 90vh;
            overflow: hidden;
            background-color: transparent;
            video {
                background-color: transparent;
                height: auto;
            }
        }
    }
    
}