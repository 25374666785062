.single-post {
    .info {
        span {
            color: $brand-color;
            font-weight: $font_weight_bold;
        }
    }
    .m-image-large {
        .image {
            height: 0;
            padding-bottom: 100%;
            .img {
                background-size: contain;
            }   
        }
    }
}