
.hero-started {
    .slide {
        img {
            object-fit: contain;
            border-radius: 0px;
            filter: brightness(1) invert(1);
        }
    }
    .home.light-skin & {
        .slide {
            img {
                object-fit: contain;
                border-radius: 0px;
                filter: none;
            }
        } 
    }
}
.testimonials-item {
    .text {
        min-height: 150px;
        width: 80%;
        margin: auto;
        margin-top: 40px;
    }
    .image {
        width: 250px;
        height: 250px;
    }
}
.blog-items {
    .archive-item {
        .desc {
            h3 {
                span {
                    display: block;
                    font-size: $default_size;
                    font-style: normal;
                    font-weight: 100;
                    position: absolute;
                    top: -$default_size * 2;
                    color: $brand-color;
                    @include breakpoint(small down) { 
                        width: auto;
                        position: relative;
                        top: auto;
                    }
                }
            }
            .category {
                width: 250px;
                @include breakpoint(small down) { 
                    width: auto;
                    position: relative;
                }
            }
        }
        .image {
            background-color: rgba($color: $dark_color, $alpha: .6);
            .home.light-skin & {
                background-color: rgba($color: $dark_color, $alpha: .2);
            }
        }
    }
}