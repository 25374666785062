a.btn, .btn, a.btn-link, .btn-link, button, input[type="submit"] {
    border-color: $brand-color;
}
a.btn:before, .btn:before, a.btn-link:before, .btn-link:before, button:before, .skills-item .dots.dots-81 .dot:nth-child(-n+9), .skills-item .dots.dots-82 .dot:nth-child(-n+9), .skills-item .dots.dots-83 .dot:nth-child(-n+9), .skills-item .dots.dots-84 .dot:nth-child(-n+9), .skills-item .dots.dots-85 .dot:nth-child(-n+9), .skills-item .dots.dots-86 .dot:nth-child(-n+9), .skills-item .dots.dots-87 .dot:nth-child(-n+9), .skills-item .dots.dots-88 .dot:nth-child(-n+9), .skills-item .dots.dots-89 .dot:nth-child(-n+9), .skills-item .dots.dots-90 .dot:nth-child(-n+9), .skills-item .value .num {
    background: $brand-color;
}
a.lnk, .lnk, .services-item .icon, .numbers-item .icon, a:visited {
    color: $brand-color;
}

// body.light-skin .works-item .image:before, body.light-skin .works-item .image:after,body.light-skin .works-item .image .img:before, body.light-skin .works-item .image .img:after {
//     background-color: #f7f5f2;    
// }
// body .works-item .image:before, body .works-item .image:after,body .works-item .image .img:before, body .works-item .image .img:after {
//     background-color: #222a36;
// }

.home {
    #jarallax-container-* {
        filter: brightness(1) invert(1);
    }
    &.light-skin {
        #jarallax-container-* {
            filter: none;
        }
    }
}