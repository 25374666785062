.header {
    .logo {
        width: 150px;
        img {
            filter: brightness(1) invert(1);
        }
    }
    .home.light-skin & {
        .logo {
            img {
                filter: none;
            }
        }
    }
    // .sw-before {
    //     svg {
    //         path {
    //             fill: $bg-dark-color;
    //         }
    //     }
    // }
    // .sw-after {
    //     svg {
    //         path {
    //             fill: $bg-color;
    //         }
    //     }
    // }
    
}