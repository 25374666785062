.products-list {
    .works-items {
        &.works-masonry-items {
            .works-item {
                position: relative;
                .desc {
                    
                    // width: 90%;
                    
                    margin: auto;
                    .name {
                        font-size: $title_h1_size;
                    }
                }
            }
        }
    }  
}